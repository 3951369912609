<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="安全提醒" />
    </van-sticky>
    <div>
      <div style="text-align: center; margin-top: 100px; margin-bottom: 10px">
        <img src="/image/danger.png" style="max-width: 200px" />
      </div>

      <div style="text-align: center; margin-top: 25px; margin-bottom: 30px">
        <p style="font-size: 20px; color: red; margin: 15px">
          您的账号正在使用系统默认密码
        </p>
        <h2>为了您账号安全和公司信息安全，请先修改密码，再使用系统</h2>
      </div>
      <div>
        <!-- <div class="btn-box">
          <van-button size="large" round block @click="tohome"
            >继续使用</van-button
          >
        </div> -->
        <div class="btn-box">
          <van-button
            type="info"
            size="large"
            round
            block
            @click="tochangePassWord"
            >去修改密码</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "prechangepassword",
  data() {
    return {};
  },

  methods: {
    tohome() {
      this.$router.push({
        path: "/",
        query: { status: "0" },
      });
    },
    tochangePassWord() {
      this.$router.push({
        path: "/user/changePassWord",
        query: { pwd: "znpz" },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.btn-box {
  margin: 20px;
}
</style>
